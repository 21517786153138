import { apiConnection } from "@/services/api-connection";
import { fileManager } from "@/services/file/file-manager";
import { notificationManager } from "@/services/utilities/notification-manager";
import { i18n } from "@/i18n";
import { mainStore, trainingCourseStore, triadStore } from "@/main";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { useTriadStore } from "@/store/triad/triad";
import { messengerMessageManager } from "@/services/messenger-message/messenger-message-manager";

class SkillManager {
  SKILL_CONFIG_FROM = 0;
  SKILL_REFERENTIAL_FORM = 1;
  SKILL_SESSION_FORM = 2;
  getPrefixURL = () => {
    return (
      "/app/skill/" +
      trainingCourseStore.trainingCourse.id +
      "/" +
      triadStore.triad.id
    );
  };

  postSkills = (skills) => {
    return apiConnection
      .post(
        this.getPrefixURL() + "/save/" + triadStore.currentSession.id,
        {
          skills: skills,
        },
        true,
      )
      .then((response) => response);
  };

  getTriadSkills = () => {
    return apiConnection
      .get(this.getPrefixURL() + "/list", {})
      .then((response) => response);
  };

  downloadSkillToPdf = () => {
    return apiConnection
      .get(this.getPrefixURL() + "/export/" + triadStore.currentSession.id, {})
      .then((res) => {
        messengerMessageManager.processMessage(res);
      });
  };

  downloadSkillToExcel = () => {
    return apiConnection
      .post(
        this.getPrefixURL() + "/export-excel/" + triadStore.currentSession.id,
        {},
      )
      .then((res) => {
        messengerMessageManager.processMessage(res);
      });
  };

  getPdf = () => {
    mainStore.fileDownloadRequest();
    return apiConnection
      .get(
        this.getPrefixURL() + "/export",
        {
          triad: triadStore.triad.id,
          finalEval: 0,
        },
        true,
      )
      .then((success) => {
        if (success.data) {
          fileManager.saveFile(
            success.fileName,
            success.data,
            "application/pdf",
          );
        } else {
          notificationManager.showAlert("error", i18n.global.t("error_file"));
        }
        mainStore.fileDownloadSuccess();
      });
  };

  getTriadSkillsHistoric = (skill) => {
    return apiConnection
      .get(this.getPrefixURL() + "/historic" + "/" + skill, {}, true)
      .then((response) => response);
  };

  getJustificativeForm(id = null) {
    return apiConnection
      .get(
        "/app/skill/" +
          trainingCourseStore.trainingCourse.id +
          "/" +
          triadStore.triad.id +
          "/justificative-form" +
          (id ? "/" + id : ""),
      )
      .then((response) => response);
  }

  submitJustificativeForm(id, result) {
    return apiConnection
      .put(
        "/app/skill/" +
          trainingCourseStore.trainingCourse.id +
          "/" +
          triadStore.triad.id +
          "/justificative-post" +
          (id ? "/" + id : ""),
        result,
      )
      .then((response) => response);
  }

  initSession() {
    triadStore.setCurrentSession(
      triadStore.sessions.find(
        (session) =>
          new Date(session.startDate) < Date.now() &&
          new Date(session.endDate) > Date.now(),
      ),
    );
  }

  sessionCharts() {
    const trainingCourse = useTrainingCourseStore().trainingCourse;
    const triad = useTriadStore().triad;
    const session = useTriadStore().currentSession;
    return apiConnection
      .get(
        "/app/skill/" +
          trainingCourse.id +
          "/" +
          triad.id +
          "/charts/" +
          session.id,
      )
      .then((response) => response);
  }

  getSkillSessionAdminForm() {
    return apiConnection
      .get("/app/admin/skill/session/form")
      .then((response) => response);
  }

  postSkillSessionAdminForm(sessionsToCreate) {
    return apiConnection
      .put("/app/admin/skill/session", sessionsToCreate)
      .then((response) => response);
  }

  getSkillConfig(trainingCourse, triad) {
    return apiConnection
      .get("/app/skill/" + trainingCourse + "/" + triad + "/config")
      .then((response) => response);
  }
}

export const skillManager = new SkillManager();
