import { apiConnection } from "@/services/api-connection";
import { notificationManager } from "@/services/utilities/notification-manager";
import { i18n } from "@/i18n";
import { stringManager } from "@/services/utilities/string-manager";
import router from "@/router";
import { loginManager } from "@/services/security/login-manager";
import { studeaManagerStore, trainingCourseStore } from "@/main";
import { messengerMessageManager } from "@/services/messenger-message/messenger-message-manager";

const URL_MENU = "/app/studea-manager/menu/";
const URL = "/app/studea-manager/";

class StudeaManagerManager {
  STUDEA_ADMIN_COMPONENT = "STUDEA_ADMIN_COMPONENT";
  STUDEA_MANAGER_TRAINING_DESIGN = "STUDEA_MANAGER_TRAINING_DESIGN";
  STUDEA_MANAGER_WEBLINKS = "STUDEA_MANAGER_WEBLINKS";
  STUDEA_MANAGER_QUESTIONNAIRES = "STUDEA_MANAGER_QUESTIONNAIRES";
  STUDEA_MANAGER_QUESTIONNAIRES_SIGN = "STUDEA_MANAGER_QUESTIONNAIRES_SIGN";
  STUDEA_MANAGER_QUESTIONNAIRES_EXPORT = "STUDEA_MANAGER_QUESTIONNAIRES_EXPORT";
  STUDEA_MANAGER_QUESTIONNAIRES_INCIDENT =
    "STUDEA_MANAGER_QUESTIONNAIRES_INCIDENT";
  STUDEA_MANAGER_DUPLICATE_QUESTIONNAIRE =
    "STUDEA_MANAGER_DUPLICATE_QUESTIONNAIRE";
  STUDEA_MANAGER_DASHBOARD_NOTE = "STUDEA_MANAGER_DASHBOARD_NOTE";
  STUDEA_MANAGER_TRIAD = "STUDEA_MANAGER_TRIAD";
  STUDEA_MANAGER_TRIAD_IMPORT = "STUDEA_MANAGER_TRIAD_IMPORT";
  STUDEA_MANAGER_TRIAD_LAST_MAIL = "STUDEA_MANAGER_TRIAD_LAST_MAIL";
  STUDEA_MANAGER_TRIAD_CONTRACT_RUPTURE_VALIDATOR =
    "STUDEA_MANAGER_TRIAD_CONTRACT_RUPTURE_VALIDATOR";
  STUDEA_MANAGER_TRIAD_CONTRACT_RUPTURE_NOTIFY =
    "STUDEA_MANAGER_TRIAD_CONTRACT_RUPTURE_NOTIFY";
  STUDEA_MANAGER_USER = "STUDEA_MANAGER_USER";
  STUDEA_MANAGER_ALLOWED_TO_SWITCH = "STUDEA_MANAGER_ALLOWED_TO_SWITCH";
  STUDEA_MANAGER_SKILLS = "STUDEA_MANAGER_SKILLS";
  STUDEA_MANAGER_SKILLS_ENTERING = "STUDEA_MANAGER_SKILLS_ENTERING";
  STUDEA_MANAGER_SKILLS_DASHBOARD = "STUDEA_MANAGER_SKILLS_DASHBOARD";
  STUDEA_MANAGER_SKILLS_EXPORT = "STUDEA_MANAGER_SKILLS_EXPORT";
  STUDEA_MANAGER_DUPLICATE_SKILL = "STUDEA_MANAGER_DUPLICATE_SKILL";
  STUDEA_MANAGER_DOCUMENTS_UPLOAD = "STUDEA_MANAGER_DOCUMENTS_UPLOAD";
  STUDEA_MANAGER_DOCUMENTS_GLOBAL = "STUDEA_MANAGER_DOCUMENTS_GLOBAL";
  STUDEA_MANAGER_DOCUMENTS_NOTIFY = "STUDEA_MANAGER_DOCUMENTS_NOTIFY";
  STUDEA_MANAGER_QUALITY_STATS = "STUDEA_MANAGER_QUALITY_STATS";
  STUDEA_MANAGER_HISTORY = "STUDEA_MANAGER_HISTORY";
  STUDEA_MANAGER_ABSENCE = "STUDEA_MANAGER_ABSENCE";
  STUDEA_MANAGER_ABSENCE_ACCESS_DATE = "STUDEA_MANAGER_ABSENCE_ACCESS_DATE";
  STUDEA_MANAGER_ABSENCE_NEW_NOTIFICATION =
    "STUDEA_MANAGER_ABSENCE_NEW_NOTIFICATION";
  STUDEA_MANAGER_ABSENCE_EXCEEDING = "STUDEA_MANAGER_ABSENCE_EXCEEDING";
  STUDEA_MANAGER_ABSENCE_JUSTIFY_NOTIFICATION =
    "STUDEA_MANAGER_ABSENCE_JUSTIFY_NOTIFICATION";
  STUDEA_MANAGER_COURSE = "STUDEA_MANAGER_COURSE";
  STUDEA_MANAGER_TEACHERS = "STUDEA_MANAGER_TEACHERS";
  STUDEA_MANAGER_NOTE = "STUDEA_MANAGER_NOTE";
  STUDEA_MANAGER_NOTEPAD = "STUDEA_MANAGER_NOTEPAD";
  STUDEA_MANAGER_REGISTRATION = "STUDEA_MANAGER_REGISTRATION";
  STUDEA_MANAGER_REGISTRATION_IMPORT = "STUDEA_MANAGER_REGISTRATION_IMPORT";
  STUDEA_MANAGER_REGISTRATION_DASHBOARD =
    "STUDEA_MANAGER_REGISTRATION_DASHBOARD";
  STUDEA_MANAGER_REGISTRATION_SYNC_YPAREO_MANUAL =
    "STUDEA_MANAGER_REGISTRATION_SYNC_YPAREO_MANUAL";
  STUDEA_MANAGER_REGISTRATION_EXPORT = "STUDEA_MANAGER_REGISTRATION_EXPORT";
  STUDEA_MANAGER_REGISTRATION_NOTIFY_END =
    "STUDEA_MANAGER_REGISTRATION_NOTIFY_END";
  STUDEA_MANAGER_REGISTRATION_ERROR_NOTIFY =
    "STUDEA_MANAGER_REGISTRATION_ERROR_NOTIFY";
  STUDEA_MANAGER_REGISTRATION_NOTIFY_QUOTE_VALIDATED =
    "STUDEA_MANAGER_REGISTRATION_NOTIFY_QUOTE_VALIDATED";
  STUDEA_MANAGER_REGISTRATION_NOTIFY_ADD_MISSION =
    "STUDEA_MANAGER_REGISTRATION_NOTIFY_ADD_MISSION";
  STUDEA_MANAGER_REGISTRATION_DOWNLOAD_DOCUMENTS =
    "STUDEA_MANAGER_REGISTRATION_DOWNLOAD_DOCUMENTS";
  STUDEA_MANAGER_REGISTRATION_NOTIFY_NEW =
    "STUDEA_MANAGER_REGISTRATION_NOTIFY_NEW";
  STUDEA_MANAGER_MESSAGE_MODERATION = "STUDEA_MANAGER_MESSAGE_MODERATION";
  STUDEA_MANAGER_MESSAGE_CONTACT = "STUDEA_MANAGER_MESSAGE_CONTACT";
  STUDEA_MANAGER_MAIL = "STUDEA_MANAGER_MAIL";
  STUDEA_MANAGER_MAIL_TEMPLATES = "STUDEA_MANAGER_MAIL_TEMPLATES";

  getMenu(id) {
    return apiConnection
      .get(URL_MENU + id, [], true)
      .then((response) => response);
  }

  remove(
    alias,
    trainingCourseId = null,
    id = null,
    tradGender = 1,
    generic = true,
  ) {
    const url =
      "/app/studea-manager" +
      (trainingCourseId ? "/" + trainingCourseId : "") +
      (generic ? "/generic" : "") +
      "/" +
      alias +
      "/delete/" +
      id;

    const tradPrefix = stringManager.toCamelCase(alias);

    return new Promise((resolve) => {
      notificationManager
        .showAlert(
          "error",
          i18n.global.tc("global.delete.title", tradGender, {
            name: i18n.global
              .tc("studea_manager." + tradPrefix + ".title", 1)
              .toLowerCase(),
          }),
          i18n.global.tc("global.delete.sub_title", tradGender, {
            name: i18n.global
              .tc("studea_manager." + tradPrefix + ".title", 1)
              .toLowerCase(),
          }),
          true,
          true,
        )
        .then((data) => {
          if (data.isConfirmed) {
            apiConnection.delete(url, {}).then((data) => {
              resolve(data);
              notificationManager.showNotification(
                data.hasError ? "error" : "success",
                data.hasError
                  ? data.detail[0].message
                  : i18n.global.tc("global.delete.success", tradGender, {
                      name: i18n.global.tc(
                        "studea_manager." + tradPrefix + ".title",
                        1,
                      ),
                    }),
              );
            });
          }
        });
    });
  }

  removeFromSession(
    alias,
    trainingCourseId = null,
    id = null,
    tradGender = 1,
    generic = true,
    sessionId = null,
  ) {
    const url =
      "/app/studea-manager" +
      (trainingCourseId ? "/" + trainingCourseId : "") +
      (generic ? "/generic" : "") +
      "/skill/session/" +
      alias +
      "/" +
      sessionId +
      "/" +
      id;
    let tradPrefix;

    if (alias === "remove-category") {
      tradPrefix = "category";
    } else {
      tradPrefix = "skill";
    }

    return new Promise((resolve) => {
      notificationManager
        .showAlert(
          "error",
          i18n.global.tc("global.delete.title", tradGender, {
            name: i18n.global
              .tc("studea_manager." + tradPrefix + ".title", 1)
              .toLowerCase(),
          }),
          i18n.global.tc("global.delete.sub_title", tradGender, {
            name: i18n.global
              .tc("studea_manager." + tradPrefix + ".title", 1)
              .toLowerCase(),
          }),
          true,
          true,
        )
        .then((data) => {
          if (data.isConfirmed) {
            apiConnection.put(url, {}).then((data) => {
              resolve(data);
              notificationManager.showNotification(
                data.hasError ? "error" : "success",
                data.hasError
                  ? data.detail[0].message
                  : i18n.global.tc("global.delete.success", tradGender, {
                      name: i18n.global.tc(
                        "studea_manager." + tradPrefix + ".title",
                        1,
                      ),
                    }),
              );
            });
          }
        });
    });
  }

  censorMessage(trainingCourseId = null, id = null) {
    const url =
      "/app/studea-manager" +
      (trainingCourseId ? "/" + trainingCourseId : "") +
      "/message/censor/" +
      id;

    return new Promise((resolve) => {
      notificationManager
        .showAlert(
          "error",
          i18n.global.t("studea_manager.moderation.alert_title"),
          i18n.global.t("studea_manager.moderation.alert_sub_title"),
          true,
          true,
          i18n.global.t("studea_manager.moderation.censor"),
          i18n.global.t("studea_manager.moderation.restore"),
        )
        .then((data) => {
          apiConnection
            .put(url, { censor: data.isConfirmed ? 1 : 0 })
            .then((data) => {
              resolve(data);
              notificationManager.showNotification(
                data.hasError ? "error" : "success",
                data.hasError
                  ? data.detail[0].message
                  : i18n.global.t("studea_manager.moderation.censor_success"),
              );
            });
        });
    });
  }

  removeTriadMember(trainingCourseId, id, profile, userId) {
    const url =
      "/app/studea-manager/" +
      trainingCourseId +
      "/triad/delete-member/" +
      id +
      "/" +
      userId +
      "/" +
      profile;

    return new Promise((resolve) => {
      notificationManager
        .showAlert(
          "error",
          i18n.global.tc("global.delete.title", 1, {
            name: i18n.global
              .tc("studea_manager.triad.delete_member.title", 1)
              .toLowerCase(),
          }),
          i18n.global.tc("global.delete.sub_title", 1, {
            name: i18n.global
              .tc("studea_manager.triad.delete_member.title", 1)
              .toLowerCase(),
          }),
          true,
          true,
        )
        .then((data) => {
          if (data.isConfirmed) {
            apiConnection.delete(url, {}).then((data) => {
              resolve(data);
              notificationManager.showNotification(
                data.hasError ? "error" : "success",
                data.hasError
                  ? data.detail[0].message
                  : i18n.global.tc("global.delete.success", 1, {
                      name: i18n.global.tc("studea_manager.triad.title", 1),
                    }),
              );
            });
          }
        });
    });
  }

  removeTriadTutors(trainingCourseId, profile) {
    const url =
      "/app/studea-manager/" +
      trainingCourseId +
      "/triad/delete-all-members-type/" +
      profile;

    return new Promise((resolve) => {
      notificationManager
        .showAlert(
          "error",
          i18n.global.tc("global.delete.title", 3, {
            name: i18n.global
              .tc(
                profile === 2
                  ? "studea_manager.triad.delete_all_tutor.title"
                  : "studea_manager.triad.delete_all_map.title",
                1,
              )
              .toLowerCase(),
          }),
          i18n.global.tc("global.delete.sub_title", 3, {
            name: i18n.global
              .tc(
                profile === 2
                  ? "studea_manager.triad.delete_all_tutor.title"
                  : "studea_manager.triad.delete_all_map.title",
                1,
              )
              .toLowerCase(),
          }),
          true,
          true,
        )
        .then((data) => {
          if (data.isConfirmed) {
            apiConnection.delete(url, {}).then((data) => {
              resolve(data);
              notificationManager.showNotification(
                data.hasError ? "error" : "success",
                data.hasError
                  ? data.detail[0].message
                  : i18n.global.tc("global.delete.success", 3, {
                      name: i18n.global.tc(
                        profile === 2
                          ? "studea_manager.triad.tutor.title"
                          : "studea_manager.triad.map.title",
                        2,
                      ),
                    }),
              );
            });
          }
        });
    });
  }

  getQuestionnaireConfig(trainingCourseId, id = null) {
    return apiConnection
      .get(
        "/app/studea-manager/" +
          trainingCourseId +
          "/questionnaire/form" +
          (id ? "/" + id : "/add"),
        [],
        true,
      )
      .then((response) => response);
  }

  sort(alias, trainingCourseId, id, position, generic = true, type = null) {
    const url =
      "/app/studea-manager/" +
      trainingCourseId +
      "/" +
      (generic ? "generic/" : "") +
      alias +
      "/sort/" +
      id +
      "/" +
      position;
    const tradPrefix = stringManager.toCamelCase(alias);
    let params = {};
    if (type) {
      params["type"] = type;
    }

    return apiConnection.put(url, params).then((response) => {
      if (response) {
        notificationManager.showNotification(
          "success",
          i18n.global.t("global.sort.success", {
            name: i18n.global
              .tc("studea_manager." + tradPrefix + ".title", 2)
              .toLowerCase(),
          }),
        );
      }
    });
  }

  copy(
    alias,
    trainingCourseId,
    id,
    tradGender = 1,
    generic = true,
    params = {},
  ) {
    const url =
      "/app/studea-manager/" +
      trainingCourseId +
      (generic ? "/generic/" : "/") +
      alias +
      "/copy/" +
      id;
    const tradPrefix = stringManager.toCamelCase(alias);
    return apiConnection.post(url, params).then((response) => {
      if (response) {
        notificationManager.showNotification(
          "success",
          i18n.global.tc("global.copy.success", tradGender, {
            name: i18n.global.tc("studea_manager." + tradPrefix + ".title", 2),
          }),
        );
      }
    });
  }

  editModule(trainingCourseId, id, params = {}) {
    const url =
      "/app/studea-manager/" +
      trainingCourseId +
      "/training-course-module-triad/edit/" +
      id;
    return apiConnection.put(url, params).then((response) => {
      if (response) {
        notificationManager.showNotification(
          "success",
          i18n.global.t(
            "studea_manager.module." +
              (params.visible ? "active" : "unactive") +
              ".success",
          ),
        );
        studeaManagerStore.setModuleVisibility({
          id: id,
          visible: params.visible,
        });
      } else {
        notificationManager.showNotification(
          "error",
          i18n.global.t("signed_error"),
        );
      }
      studeaManagerStore.fetchStudeaManagerMenu({
        trainingCourseId: trainingCourseId,
        refresh: false,
      });
    });
  }

  move(alias, trainingCourseId, id, dest = null, tradGender = 1, params = {}) {
    const url =
      "/app/studea-manager/" +
      trainingCourseId +
      "/" +
      (alias === "category" ? "questionnaire/category/" : alias + "/") +
      "move/" +
      (dest ? dest + "/" : "") +
      id;
    const tradPrefix = stringManager.toCamelCase(alias);
    return apiConnection.put(url, params).then((response) => {
      if (response) {
        notificationManager.showNotification(
          "success",
          i18n.global.tc("global.move.success", tradGender, {
            name: i18n.global.tc("studea_manager." + tradPrefix + ".title", 2),
          }),
        );
      }
    });
  }

  list(
    alias,
    trainingCourseId = null,
    generic = false,
    params = {},
    triadId = null,
  ) {
    return apiConnection
      .get(
        "/app/studea-manager/" +
          (trainingCourseId ? trainingCourseId + "/" : "") +
          (generic ? "generic/" : "") +
          encodeURI(alias) +
          "/list" +
          (triadId ? "/" + triadId : ""),
        params,
      )
      .then((response) => response);
  }

  post(alias, trainingCoursId, generic = false, params = {}, triadId = null) {
    const url =
      "/app/studea-manager/" +
      trainingCoursId +
      "/" +
      (generic ? "generic/" : "") +
      encodeURI(alias) +
      "/post" +
      (triadId ? "/" + triadId : "");
    return apiConnection.post(url, params);
  }

  test(alias, trainingCourseId) {
    return apiConnection
      .get(
        "/app/studea-manager/" +
          trainingCourseId +
          "/" +
          encodeURI(alias) +
          "/generate-triad-test",
      )
      .then((response) => response);
  }

  form(
    alias,
    trainingCourseId = null,
    id = null,
    params = {},
    generic = false,
    search = false,
    unique = false,
  ) {
    return apiConnection
      .get(
        "/app/studea-manager/" +
          (trainingCourseId ? trainingCourseId + "/" : "") +
          (generic ? "generic/" : "") +
          encodeURI(alias) +
          (search ? "/search" : "") +
          "/" +
          (unique ? "unique-" : "") +
          "form" +
          (id ? "/" + id : ""),
        params,
      )
      .then((response) => response);
  }

  importForm(alias, trainingCourseId) {
    return apiConnection
      .get(
        "/app/studea-manager/" +
          trainingCourseId +
          "/" +
          encodeURI(alias) +
          "/import-form",
      )
      .then((response) => response);
  }

  sendWelcome(data, trainingCourseId) {
    return apiConnection
      .post("/app/studea-manager/" + trainingCourseId + "/triad/send-welcome", {
        users: data,
      })
      .then((response) => {
        if (response) {
          notificationManager.showNotification(
            "success",
            i18n.global.t("studea_manager.triad.send_welcome_email"),
          );
        }
      });
  }

  sendNewPassword(data, trainingCourseId) {
    return apiConnection
      .post(
        "/app/studea-manager/" + trainingCourseId + "/triad/send-new-password",
        {
          users: data,
        },
      )
      .then((response) => {
        if (response) {
          notificationManager.showNotification(
            "success",
            i18n.global.t("studea_manager.triad.send_welcome_email"),
          );
        }
      });
  }

  getLastSendReport(id, trainingCourseId) {
    return apiConnection
      .get(
        "/app/studea-manager/" +
          trainingCourseId +
          "/triad/last-send-report/" +
          id,
      )
      .then((response) => response);
  }

  export(alias, trainingCourseId, params = {}) {
    return apiConnection
      .get(
        "/app/studea-manager/" +
          trainingCourseId +
          "/" +
          encodeURI(alias) +
          "/export",
        params,
      )
      .then((res) => {
        messengerMessageManager.processMessage(res);
      });
  }

  isStudeaManager(id) {
    const list = trainingCourseStore.trainingCourseList;
    if (Object.keys(list).length) {
      const trainingCourse = list.find((item) => item.id === parseInt(id));
      if (trainingCourse && trainingCourse.isStudeaManager) {
        return true;
      }
    }
    return false;
  }

  switchUser(id) {
    loginManager.storeInitialToken();
    return apiConnection.get("/app/user/switch/" + id).then((response) => {
      if (response.token && response.refreshToken) {
        router.push({
          name: "loginSwitch",
          query: { token: response.token, refreshToken: response.refreshToken },
        });
      }
    });
  }

  restoreUser() {
    window.localStorage.removeItem("studea-oldTriad");
    window.localStorage.removeItem("studea-oldTrainingCourse");
    const initialToken = window.sessionStorage.getItem("studea-initial-token");
    const initialRefreshToken = window.sessionStorage.getItem(
      "studea-initial-refreshToken",
    );
    const stayConnected =
      window.localStorage.getItem("studea-stayConnected") &&
      window.localStorage.getItem("studea-stayConnected") === "1";
    if (initialToken && initialRefreshToken) {
      window.sessionStorage.removeItem("studea-initial-token");
      window.sessionStorage.removeItem("studea-initial-refreshToken");
      window.sessionStorage.removeItem("studea-switched");

      loginManager.setToken(
        initialToken,
        initialRefreshToken,
        loginManager.apiPath,
        stayConnected,
      );

      // Rediriger vers la page courante
      router.go(0);
    } else {
      console.error(
        "Jetons initiaux non trouvés. Impossible de restaurer l'utilisateur.",
      );
    }
  }

  toggle(item, name, isMultipleElement) {
    const itemSearch = "." + name + item;
    if (isMultipleElement) {
      document.querySelectorAll(itemSearch).forEach((row) => {
        row.classList.toggle("on");
      });
    } else {
      document.querySelector(itemSearch).classList.toggle("on");
    }
  }

  unlockReply(profile, id, triad) {
    const url = "/app/questionnaire/unlock/" + triad + "/" + id;
    return new Promise((resolve) => {
      notificationManager
        .showAlert(
          "question",
          i18n.global.t(
            "studea_manager.dashboard.questionnaire.unlock_questionnaire",
          ),
          i18n.global.t(
            "studea_manager.dashboard.questionnaire.unlocked_confirmation",
          ),
          true,
          true,
        )
        .then((res) => {
          if (res.isConfirmed) {
            apiConnection.get(url, { profile: profile }).then((res) => {
              if (res) {
                resolve(true);
                notificationManager.showNotification(
                  "success",
                  i18n.global.t(
                    "studea_manager.dashboard.questionnaire.success_unlock",
                  ),
                );
              }
            });
          }
        });
    });
  }

  unlockAllReplies(id, trainingCourseId) {
    return new Promise((resolve) => {
      const url =
        "/app/studea-manager/" + trainingCourseId + "/unlock-all/" + id;
      notificationManager
        .showAlert(
          "error",
          i18n.global.t(
            "studea_manager.dashboard.questionnaire.unlock_all_questionnaires",
          ),
          i18n.global.t(
            "studea_manager.dashboard.questionnaire.unlocked_confirmation",
          ),
          true,
          true,
        )
        .then((res) => {
          if (res.isConfirmed) {
            apiConnection.get(url, {}).then((res) => {
              if (res) {
                resolve(true);
                notificationManager.showNotification(
                  "success",
                  i18n.global.t(
                    "studea_manager.dashboard.questionnaire.success_all_unlock",
                  ),
                );
              }
            });
          }
        });
    });
  }

  sendOneEmailReminder(data, trainingCourseId) {
    notificationManager
      .showAlert(
        "question",
        i18n.global.t(
          "studea_manager.dashboard.questionnaire.send_email_reminder",
        ),
        i18n.global.t(
          "studea_manager.dashboard.questionnaire.send_email_reminder_confirmation",
        ),
        true,
        true,
      )
      .then((response) => {
        if (response.isConfirmed) {
          studeaManagerManager.post(
            "questionnaire/email-reminder",
            trainingCourseId,
            false,
            data,
          );
        }
      });
  }

  exportToExcel(url) {
    //use download function
    console.log("Exporter le dashboard en Excel depuis : " + url);
  }

  getDashboardData(trainingCourse, params) {
    return apiConnection.get(
      "/app/studea-manager/" + trainingCourse + "/dashboard",
      params,
    );
  }

  getDuplicationForm(code) {
    return apiConnection.get(
      "/app/studea-manager/duplication/" + code + "/form",
    );
  }

  postDuplicationForm(code, data) {
    return new Promise((resolve) => {
      notificationManager
        .showAlert(
          "question",
          i18n.global.t("studea_manager.confirm_duplication"),
          null,
          true,
          true,
        )
        .then((res) => {
          if (res.isConfirmed) {
            apiConnection
              .post("/app/studea-manager/duplication/" + code + "/post", data)
              .then((res) => {
                if (res) {
                  resolve(true);
                  notificationManager.showNotification(
                    "success",
                    i18n.global.t("studea_manager.data_duplicated_success"),
                  );
                }
              });
          }
        });
    });
    // return apiConnection.post(
    //   "/app/studea-manager/duplication/" + code + "/post",
    //   data,
    // );
  }

  getReferentialList(trainingCourseId) {
    return apiConnection
      .get("/app/studea-manager/" + trainingCourseId + "/skill/referentials")
      .then((response) => response);
  }

  getReferentialSessionForm(trainingCourseId) {
    return apiConnection
      .get(
        "/app/studea-manager/" +
          trainingCourseId +
          "/skill/referentials/session/form",
      )
      .then((response) => response);
  }

  postReferentialSession(trainingCourseId, sessionId, data) {
    return apiConnection
      .post(
        "/app/studea-manager/" +
          trainingCourseId +
          "/skill/post-referential-session/" +
          sessionId,
        data,
      )
      .then((response) => response);
  }

  getStatistic() {
    return apiConnection
      .get("/app/studea-manager/statistic/list")
      .then((response) => response);
  }

  exportTriads = (trainingCourseId) => {
    return apiConnection
      .get(URL + trainingCourseId + "/triad/export-all")
      .then((res) => {
        messengerMessageManager.processMessage(res);
      });
  };

  getStudeaManagerSkillConfig(trainingCourse) {
    return apiConnection
      .get("/app/studea-manager/" + trainingCourse + "/skill/config")
      .then((response) => response);
  }
}

export const studeaManagerManager = new StudeaManagerManager();
