export default {
  action_to_realize: "Actions à réaliser",
  sign_action: "Enregistrer et signer",
  complete: "Compléter",
  toComplete: "À compléter",
  due_date: "Échéance",
  toSign: "À signer",
  description: "Description",
  yearProgression: "Progression de l'année",
  progression: "Progression",
  your_progression: "Votre taux de remplissage",
  triad_progression: "Taux de remplissage trinôme",
  question: "Question",
  incoming: "En attente",
  signature: "Signature",
  questionnaire: "Questionnaire",
  questionnaires: "Questionnaires",
  yourAnswer: "Votre réponse : ",
  answerHere: "Répondez ici",
  commentHere: "Laissez un commentaire ici",
  comment: "Commenter la réponse",
  answerComment: "Répondre au commentaire",
  sendComment: "Poster le commentaire",
  sign: "Signer",
  draft: "Enregistrer en brouillon",
  sharedDraft: "Enregistrer et partager",
  downloadPdf: "Télécharger le questionnaire",
  downloadPdfModel: "Télécharger le modèle",
  interviewDate: "Date de visite en entreprise",
  youAnswered: "Vous avez répondu :",
  sendFile: "Envoyer un fichier",
  sendDocument: "Envoyer un document",
  takePicture: "Prendre une photo",
  fileSource: "Source du fichier",
  yesSign: "Oui, je signe",
  questionnaire_remove_title: "Supprimer un questionnaire",
  questionnaire_remove_sub_title:
    "Êtes-vous sûr de vouloir supprimer ce questionnaire ?",
  copy_questionnaire: "Copier le questionnaire",
  questionnaire_copy_success: "Questionnaire copié avec succès !",
  questionnaire_removed_success: "Questionnaire supprimé avec succès !",
  questionnaires_copied: "Versions du questionnaire ci-dessus",
  signMessage:
    "Êtes-vous sûr de vouloir signer le questionnaire ? Vous ne pourrez plus modifier vos réponses par la suite.",
  row_add_success: "Ligne ajoutée avec succès !",
  comment_add_success: "Commentaire ajouté avec succès !",
  deleteMessage: "Êtes-vous sûr de vouloir supprimer cette ligne ?",
  download_report: "Télécharger le rapport",
  signatory: "Signataire",
  signatories: "Signataires",
  expected_signature: "Signature prévue avant le",
  signed_on: "Signé le",
  commented_by: "Commenté par",
  success: "Sauvegardé avec succès !",
  draftSuccess: "Brouillon sauvegardé avec succès !",
  sharedSuccess: "Brouillon partagé avec succès !",
  signedSuccess: "Signé avec succès !",
  deletedQuestionnaire: "Ce questionnaire a été supprimé",
  addLine: "Ajouter une ligne",
  "Competence calculated by your answers to the questionnaires":
    "Compétence calculée par vos réponses aux questionnaires",
  open_from: "Ouverture du",
  open_to: "au",
  marks: "Notes",
  block_sign:
    "Signature du questionnaire possible uniquement après la validation des autres membres du trinôme.",
  no_mark: "Aucune note pour le moment",
  "Download dates": "Télécharger les dates",
  "Print all the questionnaires": "Imprimer l'ensemble des questionnaires",
  answered: "a répondu le",
  remove_answer_title: "Suppression d'une ligne",
  remove_answer: "Êtes-vous sur de vouloir supprimer cette ligne de réponse ?",
  to_be_completed_from: " À renseigner du ",
  to: " au ",
  open_at: "Ouverture le ",
  closed_at: "Clôturé au ",
  reopened: "Questionnaire ré-ouvert",
  signed_at: "Signé le ",
  no_questionnaire: ({ plural }) =>
    plural([
      "Aucun questionnaire n'est renseigné pour cette année",
      "Aucun questionnaire n'est renseigné pour cette catégorie",
    ]),
};
