<template>
  <router-view></router-view>
  <MobileMenuComponent />
</template>

<script>
import MobileMenuComponent from "@/components/MobileMenuComponent.vue";
import { mapActions, mapState } from "pinia";
import { colorManager } from "@/services/platform/color-manager";
import { trainingCourseManager } from "@/services/training-course/training-course-manager";
import { userManager } from "@/services/security/user-manager";
import { useTriadStore } from "@/store/triad/triad";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { usePlatformStore } from "@/store/platform/platform";
import { useUserStore } from "@/store/user/user";
import { useMainStore } from "@/store/main/main";

export default {
  name: "DashboardLayoutView",
  components: { MobileMenuComponent },
  computed: {
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
    ...mapState(useTrainingCourseStore, {
      trainingCourse: (store) => store.trainingCourse,
      trainingCourseList: (store) => store.trainingCourseList,
    }),
    ...mapState(usePlatformStore, {
      questionnaireConfig: (store) => store.questionnaireConfig,
      design: (store) => store.design,
      needUpdate: (store) => store.needUpdate,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
      userProfile: (store) => store.userProfile,
    }),
  },
  watch: {
    userProfile() {
      this.setUserProfileName(userManager.getProfileKey(this.userProfile));
    },
    "trainingCourse.crews"() {
      if (
        !window.localStorage.getItem("studea-oldTriad") &&
        this.trainingCourse &&
        this.trainingCourse.crews &&
        Object.keys(this.trainingCourse.crews).length &&
        this.trainingCourse.crews[0] &&
        Object.keys(this.trainingCourse.crews[0].triads).length &&
        !this.isTrainingCourseList()
      ) {
        window.localStorage.setItem(
          "studea-oldTriad",
          this.trainingCourse.crews[0].triads[0].id,
        );
        if (!Object.keys(this.triad).length) {
          this.loadTriad();
        }
      }
      if (
        this.trainingCourse &&
        this.trainingCourse.crews &&
        Object.keys(this.trainingCourse.crews).length === 1 &&
        this.trainingCourse.crews[0] &&
        Object.keys(this.trainingCourse.crews[0].triads).length === 1 &&
        this.trainingCourseList &&
        Object.keys(this.trainingCourseList).length === 1 &&
        !this.trainingCourse.isStudeaManager
      ) {
        window.localStorage.setItem(
          "studea-oldTriad",
          this.trainingCourse.crews[0].triads[0].id,
        );
        if (this.$route.name === "TrainingCourseList") {
          this.$router.push({ name: "Dashboard" });
        }
      }
    },
    $route() {
      if (this.isModulePageNeedTriadReload()) {
        this.loadTriad();
      }
    },
  },
  mounted() {
    this.init();
    if (this.needUpdate) {
      this.getConfig();
    }
  },
  updated() {
    this.setCfaDesign();
    if (this.needUpdate) {
      this.getConfig();
    }
  },
  methods: {
    init() {
      if (
        this.trainingCourse &&
        this.trainingCourse.id &&
        this.isModulePageNeedTriadReload()
      ) {
        this.loadTriad();
      }
      if (
        (!this.trainingCourse || !Object.keys(this.trainingCourse).length) &&
        !this.isTrainingCourseList()
      ) {
        this.setAppLoading(true);
        this.fetchTrainingCourseList({}).then(() => {
          this.setCfaDesign();
          if (!Object.keys(this.trainingCourseList).length) {
            this.setAppLoading(false);
            this.$router.push({ name: "TrainingCourseList" });
          }
          if (!Object.keys(this.triad).length) {
            this.loadTriad(false);
          }
        });
      } else {
        this.setCfaDesign();
      }
    },

    loadTriad(redirection = true) {
      const triadId = window.localStorage.getItem("studea-oldTriad")
        ? window.localStorage.getItem("studea-oldTriad")
        : null;
      if (triadId && this.trainingCourse) {
        this.changeTriad({
          triadId: triadId,
          trainingCourseId: this.trainingCourse.id,
          disableLoading: true,
        }).then(() => {
          this.checkUserProfile();
        });
      } else if (
        this.$route.matched.find((item) => item.path === "/dashboard") &&
        redirection &&
        this.trainingCourse &&
        (this.trainingCourse.nbTriad > 1 ||
          (this.trainingCourseList &&
            Object.keys(this.trainingCourseList).length > 1))
      ) {
        this.$router.push({ name: "TrainingCourseList" });
      }
    },

    isModulePageNeedTriadReload() {
      const modulePagesNeedReloadTriad = [
        "MODULE_STUDEA_SKILL",
        "MODULE_STUDEA_NOTEPAD",
        "MODULE_STUDEA_COURSE",
        "MODULE_STUDEA_QUESTIONNAIRE",
        "MODULE_STUDEA_WEBLINKS",
        "MODULE_STUDEA_NOTE",
        "MODULE_STUDEA_DOCUMENT",
        "Dashboard",
      ];
      return this.$route.matched.find((item) =>
        modulePagesNeedReloadTriad.includes(item.name),
      );
    },

    isTrainingCourseList() {
      return this.$route.path.includes("training-course-list");
    },

    setColor() {
      if (this.$route.path === "/dashboard/training-course-list") {
        colorManager.setPlatformStyle();
      } else if (
        this.trainingCourse &&
        Object.keys(this.trainingCourse).length
      ) {
        colorManager.setPlatformStyle(this.trainingCourse);
      }
    },

    setLogoCfa() {
      if (this.$route.path === "/dashboard/training-course-list") {
        this.setLogoCfaPromotion(null);
      } else if (
        this.trainingCourse &&
        Object.keys(this.trainingCourse).length
      ) {
        trainingCourseManager.setNewCfaLogo(this.trainingCourse);
      }
    },

    setCfaDesign() {
      this.setColor();
      this.setLogoCfa();
    },

    getConfig() {
      this.fetchConfig();
      this.setNeedUpdate(false);
    },

    checkUserProfile() {
      this.setUserProfile(userManager.checkUserProfile(this.user, this.triad));
    },

    ...mapActions(useUserStore, {
      setUserProfileName: "setUserProfileName",
      setUserProfile: "setUserProfile",
    }),
    ...mapActions(useMainStore, { setAppLoading: "setAppLoading" }),
    ...mapActions(useTrainingCourseStore, {
      fetchTrainingCourseList: "fetchTrainingCourseList",
    }),
    ...mapActions(useTriadStore, { changeTriad: "changeTriad" }),
    ...mapActions(usePlatformStore, {
      setLogoCfaPromotion: "setLogoCfaPromotion",
      fetchConfig: "fetchConfig",
      setNeedUpdate: "setNeedUpdate",
    }),
  },
};
</script>

<style scoped></style>
