<template>
  <ModalFormComponent
    :models="models"
    :title="modalTitle"
    @submit="submitForm"
  />
  <div class="row">
    <div class="col-lg-9 order-lg-0 order-1">
      <div class="loader-block">
        <div class="widget" v-for="crew in crews" :key="crew.id">
          <div class="widget-heading flex-column flex-md-row">
            <h6 class="textStyle">{{ crew.title }} (Réf : {{ crew.id }})</h6>
            <div class="mt-2 mt-md-0">
              <router-link
                :to="{
                  name: 'studeaManagerCrewEdit',
                  params: { id: crew.id, alias: 'crew' },
                }"
                class="list__button"
                v-tooltip="{
                  title: $tc('global.edit.title', 1, {
                    name: this.$tc(
                      'studea_manager.crew.title',
                      1,
                    ).toLowerCase(),
                  }),
                }"
                v-access:[permissions]="accessManager().EDIT"
              >
                <FontAwesomeIcon icon="fa-light fa-edit" />
              </router-link>
              <button
                v-if="crew && crew.triads"
                class="list__button"
                @click="removeCrew(crew.id)"
                :disabled="Object.keys(crew.triads).length"
                v-access:[permissions]="accessManager().DELETE"
                v-tooltip="{
                  title: Object.keys(crew.triads).length
                    ? $t('studea_manager.crew.cannot_remove')
                    : $tc('global.delete.title', 1, {
                        name: this.$tc(
                          'studea_manager.crew.title',
                          1,
                        ).toLowerCase(),
                      }),
                }"
              >
                <FontAwesomeIcon
                  :icon="
                    'fa-light fa-trash' +
                    (Object.keys(crew.triads).length ? '-slash' : '')
                  "
                />
              </button>
            </div>
          </div>
          <div class="widget-content">
            <div class="list">
              <div class="list__triad -heading">
                <div
                  class="list__column textStyle -sortable"
                  @click="sort('apprentice.lastName', crew)"
                  v-if="apprenticeProfileLabel && apprenticeProfileLabel.title"
                >
                  {{ $t("studea_manager.triad.list.name") }} :
                  {{ apprenticeProfileLabel.title }}
                  <FontAwesomeIcon
                    :icon="
                      'fa-light fa-arrow-' +
                      (crew.sortWay && crew.sortWay === 'DESC' ? 'down' : 'up')
                    "
                  />
                </div>
                <div
                  class="list__column textStyle -sortable"
                  @click="sort('tutor.lastName', crew)"
                  v-if="tutorProfileLabel && tutorProfileLabel.title"
                >
                  {{ $t("studea_manager.triad.list.name") }} :
                  {{ tutorProfileLabel.title }}
                  <FontAwesomeIcon
                    :icon="
                      'fa-light fa-arrow-' +
                      (crew.sortWay && crew.sortWay === 'DESC' ? 'down' : 'up')
                    "
                  />
                </div>
                <div
                  class="list__column textStyle"
                  v-if="
                    apprenticeMasterProfileLabel &&
                    apprenticeMasterProfileLabel.title
                  "
                >
                  {{ $t("studea_manager.triad.list.name") }} :
                  {{ apprenticeMasterProfileLabel.title }}
                </div>
                <div
                  class="list__column textStyle"
                  v-if="
                    companyManagerProfileLabel &&
                    companyManagerProfileLabel.title
                  "
                >
                  {{ $t("studea_manager.triad.list.name") }} :
                  {{ companyManagerProfileLabel.title }}
                </div>
                <div class="list__column -action"></div>
              </div>
              <div
                v-for="triad in crew.triads"
                :key="triad.id"
                :class="
                  'list__triad' + (triad.active ? '' : ' -disableBgColor')
                "
              >
                <div class="list__column">
                  <div class="d-flex align-items-start" v-if="triad.apprentice">
                    <div
                      class="badge -apprentice"
                      v-if="
                        apprenticeProfileLabel &&
                        apprenticeProfileLabel.titleAbbreviation
                      "
                    >
                      {{ apprenticeProfileLabel.titleAbbreviation }}
                    </div>
                    <button
                      class="list__button"
                      v-if="triad.apprentice"
                      @click="switchUser(triad.apprentice.id)"
                      v-tooltip="{
                        title:
                          $t('studea_manager.triad.usurp.title') +
                          ' ' +
                          triad.apprentice.fullName,
                      }"
                      v-access:[permissions]="{
                        access: accessManager().ALL,
                        code: studeaManagerManager()
                          .STUDEA_MANAGER_ALLOWED_TO_SWITCH,
                      }"
                    >
                      <FontAwesomeIcon icon="fa-light fa-user" class="me-1" />
                    </button>
                    <div class="d-flex justify-content-between w-100">
                      <div class="name" v-if="triad.apprentice">
                        <router-link
                          v-if="
                            user &&
                            user.roles &&
                            user.roles.includes('ROLE_STUDEA_ADMIN')
                          "
                          v-tooltip="{
                            title:
                              $t('admin.modify') + triad.apprentice.fullName,
                          }"
                          v-access:[permissions]="accessManager().EDIT"
                          :to="{
                            name: 'profile',
                            params: { id: triad.apprentice.cryptedId },
                          }"
                          class="userTriad"
                        >
                          {{ triad.apprentice.lastName.toUpperCase() }}
                          {{
                            fistLetterToUppercase(triad.apprentice.firstName)
                          }}
                        </router-link>
                        <span v-else>
                          {{ triad.apprentice.lastName.toUpperCase() }}
                          {{
                            fistLetterToUppercase(triad.apprentice.firstName)
                          }}
                        </span>
                      </div>
                      <div class="accordion-item">
                        <button
                          v-if="
                            triad.triadUsers && findUserType(1, triad).length
                          "
                          class="button_accordion accordion collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          :data-bs-target="
                            '#panelsStayOpen-collapse-' + triad.apprentice.id
                          "
                          aria-expanded="true"
                          :aria-controls="
                            'panelsStayOpen-collapse-' + triad.apprentice.id
                          "
                        >
                          <FontAwesomeIcon
                            class="ms-2"
                            icon="fa-light fa-chevron-down"
                          />
                        </button>
                      </div>
                      <div
                        v-if="triad.contractRuptureDate"
                        class="d-flex align-items-center"
                      >
                        <FontAwesomeIcon
                          v-tooltip="{
                            title:
                              $t(
                                'studea_manager.contract.contract_rupture_date',
                              ) + format(triad.contractRuptureDate),
                          }"
                          class="text-danger"
                          icon="fa-light fa-link-slash"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="triad.apprentice"
                    :id="'panelsStayOpen-collapse-' + triad.apprentice.id"
                    class="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingOne"
                  >
                    <div class="accordion-body">
                      <div
                        class="d-flex align-items-center w-100"
                        v-for="user in findUserType(1, triad)"
                        :key="user.id"
                      >
                        <button
                          v-if="user.user && user.user.fullName"
                          class="list__button"
                          v-tooltip="{
                            title:
                              $t('studea_manager.triad.usurp.title') +
                              ' ' +
                              user.user.fullName,
                          }"
                          @click="switchUser(user.user.id)"
                          v-access:[permissions]="{
                            access: accessManager().ALL,
                            code: studeaManagerManager()
                              .STUDEA_MANAGER_ALLOWED_TO_SWITCH,
                          }"
                        >
                          <FontAwesomeIcon
                            class="me-1"
                            icon="fa-light fa-user"
                          />
                        </button>
                        <div
                          class="d-flex justify-content-between w-100"
                          v-if="user.user && user.user.fullName"
                        >
                          <span class="name">
                            {{ user.user.fullName }}
                          </span>
                          <div>
                            <button
                              class="list__secondary_button"
                              v-tooltip="{
                                title: $t(
                                  'studea_manager.triad.edit.legal_responsible',
                                ),
                              }"
                              @click="modifyTriadUser(user)"
                              v-access:[permissions]="accessManager().EDIT"
                            >
                              <FontAwesomeIcon icon="fa-light fa-edit" />
                            </button>
                            <button
                              class="list__secondary_button"
                              v-tooltip="{
                                title: $t(
                                  'studea_manager.triad.delete.legal_responsible',
                                ),
                              }"
                              v-access:[permissions]="accessManager().DELETE"
                              @click="removeTriadUser(user)"
                            >
                              <FontAwesomeIcon icon="fa-light fa-trash" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list__column">
                  <div
                    v-if="triad.tutor"
                    class="d-flex justify-content-between align-items-start w-100"
                  >
                    <div class="d-flex align-items-start w-100">
                      <div
                        class="badge -tutor"
                        v-if="
                          tutorProfileLabel &&
                          tutorProfileLabel.titleAbbreviation
                        "
                      >
                        {{ tutorProfileLabel.titleAbbreviation }}
                      </div>
                      <button
                        class="list__button"
                        @click="switchUser(triad.tutor.id)"
                        v-access:[permissions]="{
                          access: accessManager().ALL,
                          code: studeaManagerManager()
                            .STUDEA_MANAGER_ALLOWED_TO_SWITCH,
                        }"
                        v-tooltip="{
                          title:
                            $t('studea_manager.triad.usurp.title') +
                            ' ' +
                            triad.tutor.fullName,
                        }"
                      >
                        <FontAwesomeIcon icon="fa-light fa-user" class="me-1" />
                      </button>
                      <div class="d-flex justify-content-between w-100">
                        <span class="name">
                          <router-link
                            v-if="
                              user &&
                              user.roles &&
                              user.roles.includes('ROLE_STUDEA_ADMIN')
                            "
                            v-tooltip="{
                              title: $t('admin.modify') + triad.tutor.fullName,
                            }"
                            :to="{
                              name: 'profile',
                              params: { id: triad.tutor.cryptedId },
                            }"
                            class="userTriad"
                          >
                            {{ triad.tutor.lastName.toUpperCase() }}
                            {{ fistLetterToUppercase(triad.tutor.firstName) }}
                          </router-link>
                          <span v-else
                            >{{ triad.tutor.lastName.toUpperCase() }}
                            {{
                              fistLetterToUppercase(triad.tutor.firstName)
                            }}</span
                          >
                        </span>
                        <div class="accordion-item">
                          <button
                            v-if="
                              triad.triadUsers && findUserType(2, triad).length
                            "
                            class="button_accordion accordion collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            :data-bs-target="
                              '#panelsStayOpen-collapse-' + triad.tutor.id
                            "
                            aria-expanded="true"
                            :aria-controls="
                              'panelsStayOpen-collapse-' + triad.tutor.id
                            "
                          >
                            <FontAwesomeIcon
                              class="ms-2"
                              icon="fa-light fa-chevron-down"
                            />
                          </button>
                        </div>
                      </div>
                    </div>

                    <button
                      class="list__button"
                      @click="removeUser(triad.id, 2, triad.tutor.id)"
                      v-access:[permissions]="accessManager().DELETE"
                      v-tooltip="{
                        title: $t('studea_manager.triad.delete.tutor'),
                      }"
                    >
                      <FontAwesomeIcon icon="fa-light fa-trash" />
                    </button>
                  </div>
                  <div
                    v-if="triad.tutor"
                    :id="'panelsStayOpen-collapse-' + triad.tutor.id"
                    class="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingOne"
                  >
                    <div class="accordion-body">
                      <div
                        class="d-flex align-items-center w-100"
                        v-for="user in findUserType(2, triad)"
                        :key="user.id"
                      >
                        <button
                          class="list__button"
                          v-if="user.user && user.user.fullName"
                          @click="switchUser(user.user.id)"
                          v-access:[permissions]="{
                            access: accessManager().ALL,
                            code: studeaManagerManager()
                              .STUDEA_MANAGER_ALLOWED_TO_SWITCH,
                          }"
                          v-tooltip="{
                            title:
                              $t('studea_manager.triad.usurp.title') +
                              ' ' +
                              user.user.fullName,
                          }"
                        >
                          <FontAwesomeIcon
                            icon="fa-light fa-user"
                            class="me-1"
                          />
                        </button>
                        <div
                          class="d-flex justify-content-between w-100"
                          v-if="user.user && user.user.fullName"
                        >
                          <span class="name">
                            {{ user.user.fullName }}
                          </span>
                          <div class="d-flex">
                            <button
                              class="list__secondary_button"
                              v-tooltip="{
                                title: $t('studea_manager.triad.edit.tutor'),
                              }"
                              @click="modifyTriadUser(user)"
                              v-access:[permissions]="accessManager().EDIT"
                            >
                              <FontAwesomeIcon icon="fa-light fa-edit" />
                            </button>
                            <button
                              class="list__secondary_button"
                              v-tooltip="{
                                title: $t('studea_manager.triad.delete.tutor'),
                              }"
                              @click="removeTriadUser(user)"
                              v-access:[permissions]="accessManager().DELETE"
                            >
                              <FontAwesomeIcon icon="fa-light fa-trash" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="list__column">
                  <div
                    v-if="triad.apprenticeMaster"
                    class="d-flex justify-content-between align-items-start w-100"
                  >
                    <div class="d-flex align-items-start w-100">
                      <div
                        class="badge -apprenticeMaster"
                        v-if="
                          apprenticeMasterProfileLabel &&
                          apprenticeMasterProfileLabel.titleAbbreviation
                        "
                      >
                        {{ apprenticeMasterProfileLabel.titleAbbreviation }}
                      </div>
                      <div class="d-flex align-items-start w-100">
                        <button
                          class="list__button"
                          v-tooltip="{
                            title:
                              $t('studea_manager.triad.usurp.title') +
                              ' ' +
                              triad.apprenticeMaster.fullName,
                          }"
                          @click="switchUser(triad.apprenticeMaster.id)"
                          v-access:[permissions]="{
                            access: accessManager().ALL,
                            code: studeaManagerManager()
                              .STUDEA_MANAGER_ALLOWED_TO_SWITCH,
                          }"
                        >
                          <FontAwesomeIcon
                            class="me-1"
                            icon="fa-light fa-user"
                          />
                        </button>
                        <span class="d-flex justify-content-between w-100">
                          <span class="name">
                            <router-link
                              v-if="
                                user &&
                                user.roles &&
                                user.roles.includes('ROLE_STUDEA_ADMIN')
                              "
                              v-tooltip="{
                                title:
                                  $t('admin.modify') +
                                  triad.apprenticeMaster.fullName,
                              }"
                              :to="{
                                name: 'profile',
                                params: {
                                  id: triad.apprenticeMaster.cryptedId,
                                },
                              }"
                              class="userTriad"
                            >
                              {{
                                triad.apprenticeMaster.lastName.toUpperCase()
                              }}
                              {{
                                fistLetterToUppercase(
                                  triad.apprenticeMaster.firstName,
                                )
                              }}
                            </router-link>
                            <span v-else
                              >{{
                                triad.apprenticeMaster.lastName.toUpperCase()
                              }}
                              {{
                                fistLetterToUppercase(
                                  triad.apprenticeMaster.firstName,
                                )
                              }}</span
                            >
                          </span>
                          <button
                            :aria-controls="'flush-collapse' + triad.id"
                            :data-bs-target="'#flush-collapse' + triad.id"
                            aria-expanded="false"
                            data-bs-toggle="collapse"
                            v-if="
                              triad.activeContract &&
                              (triad.activeContract.apprenticeMaster2 ||
                                (triad.activeContract.apprenticeMaster &&
                                  ((triad.apprenticeMaster &&
                                    triad.activeContract.apprenticeMaster.id !==
                                      triad.apprenticeMaster.id) ||
                                    !triad.apprenticeMaster)))
                            "
                            class="button_accordion accordion collapsed"
                          >
                            <FontAwesomeIcon
                              class="ms-2"
                              icon="fa-light fa-chevron-down"
                            />
                          </button>
                        </span>
                      </div>
                    </div>

                    <button
                      class="list__button"
                      @click="
                        removeUser(triad.id, 3, triad.apprenticeMaster.id)
                      "
                      v-access:[permissions]="accessManager().DELETE"
                      v-tooltip="{
                        title: $t('studea_manager.triad.delete.map'),
                      }"
                    >
                      <FontAwesomeIcon icon="fa-light fa-trash" />
                    </button>
                  </div>
                  <div
                    v-if="
                      triad.activeContract &&
                      (triad.activeContract.apprenticeMaster2 ||
                        (triad.activeContract.apprenticeMaster &&
                          ((triad.apprenticeMaster &&
                            triad.activeContract.apprenticeMaster.id !==
                              triad.apprenticeMaster.id) ||
                            !triad.apprenticeMaster)))
                    "
                    :id="'flush-collapse' + triad.id"
                    aria-labelledby="flush-headingOne"
                    class="accordion-collapse collapse -offset"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      v-if="
                        triad.activeContract.apprenticeMaster &&
                        ((triad.apprenticeMaster &&
                          triad.apprenticeMaster.id !==
                            triad.activeContract.apprenticeMaster.id) ||
                          !triad.apprenticeMaster)
                      "
                    >
                      <button
                        v-tooltip="{
                          title:
                            $t('studea_manager.triad.usurp.title') +
                            ' ' +
                            triad.activeContract.apprenticeMaster.fullName,
                        }"
                        class="list__button"
                        @click="
                          switchUser(triad.activeContract.apprenticeMaster.id)
                        "
                        v-access:[permissions]="{
                          access: accessManager().ALL,
                          code: studeaManagerManager()
                            .STUDEA_MANAGER_ALLOWED_TO_SWITCH,
                        }"
                      >
                        <FontAwesomeIcon class="me-1" icon="fa-light fa-user" />
                      </button>
                      <router-link
                        v-if="
                          user &&
                          user.roles &&
                          user.roles.includes('ROLE_STUDEA_ADMIN')
                        "
                        v-tooltip="{
                          title:
                            $t('admin.modify') +
                            triad.activeContract.apprenticeMaster.fullName,
                        }"
                        :to="{
                          name: 'profile',
                          params: {
                            id: triad.activeContract.apprenticeMaster.cryptedId,
                          },
                        }"
                        class="userTriad"
                      >
                        {{
                          triad.activeContract.apprenticeMaster.lastName.toUpperCase()
                        }}
                        {{
                          fistLetterToUppercase(
                            triad.activeContract.apprenticeMaster.firstName,
                          )
                        }}
                      </router-link>
                      <span v-else
                        >{{
                          triad.activeContract.apprenticeMaster.lastName.toUpperCase()
                        }}
                        {{
                          fistLetterToUppercase(
                            triad.activeContract.apprenticeMaster.firstName,
                          )
                        }}</span
                      >
                    </div>
                    <div v-if="triad.activeContract.apprenticeMaster2">
                      <button
                        v-tooltip="{
                          title:
                            $t('studea_manager.triad.usurp.title') +
                            ' ' +
                            triad.activeContract.apprenticeMaster2.fullName,
                        }"
                        class="list__button"
                        @click="
                          switchUser(triad.activeContract.apprenticeMaster2.id)
                        "
                        v-access:[permissions]="{
                          access: accessManager().ALL,
                          code: studeaManagerManager()
                            .STUDEA_MANAGER_ALLOWED_TO_SWITCH,
                        }"
                      >
                        <FontAwesomeIcon class="me-1" icon="fa-light fa-user" />
                      </button>
                      <router-link
                        v-if="
                          user &&
                          user.roles &&
                          user.roles.includes('ROLE_STUDEA_ADMIN')
                        "
                        v-tooltip="{
                          title:
                            $t('admin.modify') +
                            triad.activeContract.apprenticeMaster2.fullName,
                        }"
                        :to="{
                          name: 'profile',
                          params: {
                            id: triad.activeContract.apprenticeMaster2
                              .cryptedId,
                          },
                        }"
                        class="userTriad"
                      >
                        {{
                          triad.activeContract.apprenticeMaster2.lastName.toUpperCase()
                        }}
                        {{
                          fistLetterToUppercase(
                            triad.activeContract.apprenticeMaster2.firstName,
                          )
                        }}
                      </router-link>
                      <span v-else
                        >{{
                          triad.activeContract.apprenticeMaster2.lastName.toUpperCase()
                        }}
                        {{
                          fistLetterToUppercase(
                            triad.activeContract.apprenticeMaster2.firstName,
                          )
                        }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="list__column -manager">
                  {{
                    triad.activeContract && triad.activeContract.manager
                      ? triad.activeContract.manager.fullName
                      : "--"
                  }}
                </div>
                <div class="list__column -action">
                  <div class="list__action">
                    <router-link
                      :to="{
                        name: 'studeaManagerContract',
                        params: {
                          alias: 'contract',
                          triadId: triad.id,
                        },
                      }"
                      class="list__button"
                      v-tooltip="{
                        title: $t('studea_manager.triad.contract.title'),
                      }"
                    >
                      <FontAwesomeIcon icon="fa-light fa-id-card" />
                    </router-link>
                    <button
                      class="list__button"
                      v-tooltip="{
                        title: $t('studea_manager.triad.complete.title'),
                      }"
                      @click="addSecondaryUser(triad.id)"
                      v-access:[permissions]="accessManager().EDIT"
                    >
                      <FontAwesomeIcon icon="fa-light fa-users" />
                    </button>
                    <router-link
                      :to="{
                        name: 'studeaManagerTriadEdit',
                        params: { id: triad.id, alias: 'triad' },
                      }"
                      class="list__button"
                      v-tooltip="{
                        title: $tc('global.edit.title', 1, {
                          name: this.$tc(
                            'studea_manager.triad.title',
                            1,
                          ).toLowerCase(),
                        }),
                      }"
                    >
                      <FontAwesomeIcon icon="fa-light fa-edit" />
                    </router-link>
                    <button
                      :class="
                        'list__button -triad' +
                        (triad.isAlreadyQuestionnaireSigned
                          ? ' text-danger'
                          : '')
                      "
                      @click="deleteTriad(triad.id)"
                      v-tooltip="{
                        title: deleteTranslation(triad),
                      }"
                      v-access:[permissions]="accessManager().DELETE"
                    >
                      <FontAwesomeIcon
                        :icon="
                          triad.isAlreadyQuestionnaireSigned
                            ? 'fa-solid fa-trash'
                            : 'fa-light fa-trash'
                        "
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="Object.keys(crew.triads).length" class="list__triad">
                <div class="list__column"></div>
                <div
                  class="list__column d-flex justify-content-end align-items-center"
                >
                  <div v-if="hiddenRemoveTutorsButton(crew.triads, 'tutor')">
                    {{ $t("All") }}
                    <button
                      v-access:[permissions]="accessManager().DELETE"
                      v-tooltip="{
                        title: $t('studea_manager.triad.delete_all.tutor'),
                      }"
                      class="list__button deleteAll"
                      @click="removeTutors(2)"
                    >
                      <FontAwesomeIcon icon="fa-light fa-trash" />
                    </button>
                  </div>
                </div>
                <div
                  class="list__column d-flex justify-content-end align-items-center"
                >
                  <div
                    v-if="
                      hiddenRemoveTutorsButton(crew.triads, 'apprenticeMaster')
                    "
                  >
                    {{ $t("All") }}
                    <button
                      v-access:[permissions]="accessManager().DELETE"
                      v-tooltip="{
                        title: $t('studea_manager.triad.delete_all.map'),
                      }"
                      class="list__button deleteAll"
                      @click="removeTutors(3)"
                    >
                      <FontAwesomeIcon icon="fa-light fa-trash" />
                    </button>
                  </div>
                </div>
                <div class="list__column"></div>
                <div class="list__column -action"></div>
              </div>
            </div>
          </div>
        </div>
        <BlockLoader :loading="loading" />
      </div>
    </div>
    <div class="col-lg-3 order-lg-1 order-0">
      <div class="sticky">
        <div id="action-blocks">
          <ActionsBlock
            :actions="actions"
            :collapsable="false"
            :mobileCollapsable="true"
            :title="$t('global.actions.title')"
            collapseId="actions"
            @studeaManagerTriadTestAdd="addTriadTest"
            @exportTriads="exportTriads"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { notificationManager } from "@/services/utilities/notification-manager";
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { sortManager } from "@/services/utilities/sort-manager";
import { mapActions, mapState } from "pinia";
import ModalFormComponent from "@/components/form/ModalFormComponent.vue";
import { formManager } from "@/services/form/form-manager";
import { userManager } from "@/services/security/user-manager";
import { apiConnection } from "@/services/api-connection";
import { accessManager } from "@/services/security/access-manager";
import { useUserStore } from "@/store/user/user";
import { usePlatformStore } from "@/store/platform/platform";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { dateFormatter } from "@/services/utilities/date-formatter";

export default {
  name: "TriadListView",
  components: {
    ModalFormComponent,
    FontAwesomeIcon,
    BlockLoader,
    ActionsBlock,
  },
  data() {
    return {
      actions: [
        {
          translation: "global.add.title",
          translationGender: 1,
          translationParams: {
            name: this.$tc("studea_manager.crew.title", 1).toLowerCase(),
          },
          icon: "plus",
          type: "success",
          actionType: "router",
          action: "studeaManagerCrewAdd",
          cfaStyle: false,
          params: { alias: "crew" },
          access: accessManager.EDIT,
        },
        {
          translation: "global.import.title",
          translationGender: 1,
          translationParams: {
            name: this.$tc("studea_manager.triad.title", 2).toLowerCase(),
          },
          separation: true,
          icon: "download",
          type: "success",
          actionType: "router",
          action: "studeaManagerTriadImport",
          cfaStyle: false,
          params: { alias: "triad" },
          access: {
            access: accessManager.EDIT,
            code: studeaManagerManager.STUDEA_MANAGER_TRIAD_IMPORT,
          },
        },
        {
          translation: "global.export.title",
          translationGender: 1,
          translationParams: {
            name: this.$tc("studea_manager.triad.title", 2).toLowerCase(),
          },
          icon: "file-excel",
          type: "success",
          action: "exportTriads",
          cfaStyle: false,
          params: { alias: "triad" },
          access: {
            access: accessManager.EDIT,
            code: studeaManagerManager.STUDEA_MANAGER_TRIAD_IMPORT,
          },
        },
        {
          translation: "global.update.title",
          translationGender: 1,
          translationParams: {
            name: this.$tc("studea_manager.triad.title", 2).toLowerCase(),
          },
          icon: "users",
          type: "success",
          actionType: "router",
          action: "studeaManagerTriadUpdate",
          cfaStyle: false,
          params: { alias: "triad" },
          access: {
            access: accessManager.EDIT,
            code: studeaManagerManager.STUDEA_MANAGER_TRIAD_IMPORT,
          },
        },
        {
          translation: "global.add.title",
          translationGender: 1,
          translationParams: {
            name: this.$tc("studea_manager.triad.title", 1).toLowerCase(),
          },
          icon: "plus",
          type: "success",
          actionType: "router",
          action: "studeaManagerTriadAdd",
          cfaStyle: false,
          params: { alias: "triad" },
          access: accessManager.EDIT,
        },
        {
          translation: "global.add.title",
          translationGender: 1,
          translationParams: {
            name: this.$t("studea_manager.triad.add_test", 1).toLowerCase(),
          },
          icon: "plus",
          type: "success",
          actionType: "button",
          action: "studeaManagerTriadTestAdd",
          cfaStyle: false,
          access: accessManager.EDIT,
        },
        {
          translation: "global.add.title",
          translationGender: 1,
          translationParams: {
            name: this.$tc("studea_manager.studeaUser.title", 1).toLowerCase(),
          },
          separation: true,
          icon: "plus",
          type: "success",
          actionType: "router",
          action: "studeaManagerUserAdd",
          cfaStyle: false,
          params: { alias: "studea-user" },
          access: accessManager.EDIT,
        },

        {
          translation: "studea_manager.triad.send_user_infos",
          icon: "envelope",
          type: "light text-success",
          actionType: "router",
          action: "studeaManagerTriadSendUserInfos",
          access: accessManager.VIEW,
          cfaStyle: false,
        },
      ],
      list: {},
      crews: [],
      loading: false,
      sortWay: "ASC",
      models: [],
      modalTitle: "",
      triadId: null,
      triadUser: null,
    };
  },
  computed: {
    ...mapState(useUserStore, {
      permissions: (store) => store.permissions,
      user: (store) => store.user,
    }),
    ...mapState(usePlatformStore, {
      apprenticeProfileLabel: (store) => store.apprenticeProfileLabel,
      tutorProfileLabel: (store) => store.tutorProfileLabel,
      apprenticeMasterProfileLabel: (store) =>
        store.apprenticeMasterProfileLabel,
      companyManagerProfileLabel: (store) => store.companyManagerProfileLabel,
    }),
  },
  mounted() {
    this.init();
  },
  methods: {
    hiddenRemoveTutorsButton(triads, key) {
      let display = false;
      if (triads) {
        Object.values(triads).forEach((triad) => {
          if (triad[key] && triad[key] !== null) {
            display = true;
          }
        });
      }
      return display;
    },
    studeaManagerManager() {
      return studeaManagerManager;
    },
    accessManager() {
      return accessManager;
    },
    fistLetterToUppercase(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    deleteTranslation(triad) {
      let translation =
        this.$tc("global.delete.title", 1, {
          name: this.$tc("studea_manager.triad.title", 1).toLowerCase(),
        }) + ".";
      if (triad.isAlreadyQuestionnaireSigned) {
        translation = translation.concat("\n");
        translation = translation.concat(
          this.$t("studea_manager.triad.questionnaire_triad_already_signed") +
            ".",
        );
      }
      return translation;
    },
    sort(field, crew, way = null) {
      crew.sortWay = way
        ? way
        : crew.sortWay && crew.sortWay === "DESC"
          ? "ASC"
          : "DESC";
      crew.triads = sortManager.sort(crew.triads, field, crew.sortWay);
    },

    switchUser(id) {
      studeaManagerManager.switchUser(id);
    },

    removeUser(id, profile, userId) {
      studeaManagerManager
        .removeTriadMember(
          this.$route.params.trainingCourseId,
          id,
          profile,
          userId,
        )
        .then(() => {
          this.init();
        });
    },

    deleteTriad(id) {
      studeaManagerManager
        .remove("triad", this.$route.params.trainingCourseId, id, true)
        .then(() => {
          this.init();
        });
    },
    removeTutors(profile) {
      studeaManagerManager
        .removeTriadTutors(this.$route.params.trainingCourseId, profile)
        .then(() => {
          this.init();
        });
    },

    exportSkills() {
      notificationManager.showNotification(
        "success",
        this.$t("studea_manager.skill_export.success"),
      );
    },

    exportTaught() {
      notificationManager.showNotification(
        "success",
        this.$t("studea_manager.skill_export_taught.success"),
      );
    },

    addTriadTest() {
      this.loading = true;
      studeaManagerManager
        .test("triad", this.$route.params.trainingCourseId)
        .then(() => {
          this.init();
          notificationManager.showNotification(
            "success",
            this.$t("studea_manager.triad.add_test_success"),
          );
        });
    },

    init() {
      this.loading = true;
      studeaManagerManager
        .list("triad", this.$route.params.trainingCourseId, false, [])
        .then((res) => {
          this.crews = res;
          this.loading = false;
          Object.values(this.crews).forEach((crew) => {
            this.sort("apprentice.lastName", crew, "ASC");
          });
          this.hiddenRemoveTutorsButton();
        });

      this.fetchTrainingCourseTriads({
        toggle: false,
        id: this.$route.params.trainingCourseId,
        archive: 1,
      });
    },

    addSecondaryUser(triadId) {
      this.models = [];
      this.modalTitle = "";
      this.triadId = null;
      studeaManagerManager
        .form(
          "triad/secondary-user",
          this.$route.params.trainingCourseId,
          null,
          {
            triadId: triadId,
          },
        )
        .then((data) => {
          this.triadId = triadId;
          this.models = data;
          this.modalTitle = this.$t("studea_manager.triad.add_secondary_user");
        });
    },

    submitForm(payload) {
      const result = formManager.processForm(payload);
      result["triad"] = { id: this.triadId };
      if (result.user) {
        studeaManagerManager
          .post("triad-user", this.$route.params.trainingCourseId, true, result)
          .then((response) => {
            if (response) {
              notificationManager.showNotification(
                "success",
                this.$t("studea_manager.triad.add_secondary_user_success"),
              );
              this.init();
            }
          });
      } else {
        const result = formManager.processForm(payload);
        const url =
          "/app/studea-manager/" +
          this.$route.params.trainingCourseId +
          "/generic/triad-user/post/" +
          this.triadUser.id;
        apiConnection.put(url, result);
      }
    },

    findUserType(userType, triad) {
      return userManager.findUserType(userType, triad);
    },

    removeTriadUser(user) {
      studeaManagerManager
        .remove(
          "triad-user",
          this.$route.params.trainingCourseId,
          user.id,
          1,
          true,
        )
        .then((response) => {
          if (response) {
            notificationManager.showNotification(
              "success",
              this.$t("studea_manager.triad.remove_secondary_user"),
            );
            this.init();
          }
        });
    },

    removeCrew(id) {
      studeaManagerManager
        .remove("crew", this.$route.params.trainingCourseId, id, 1, true)
        .then(() => {
          this.init();
        });
    },

    modifyTriadUser(user) {
      this.models = [];
      this.modalTitle = "";
      this.triadUser = null;
      studeaManagerManager
        .form(
          "triad/secondary-user",
          this.$route.params.trainingCourseId,
          user.id,
        )
        .then((data) => {
          this.models = data;
          this.modalTitle = this.$t("studea_manager.triad.add_secondary_user");
          this.triadUser = user;
        });
    },

    format(date) {
      return dateFormatter.format(date, "short");
    },

    exportTriads() {
      studeaManagerManager.exportTriads(this.$route.params.trainingCourseId);
    },

    ...mapActions(useTrainingCourseStore, {
      fetchTrainingCourseTriads: "fetchTrainingCourseTriads",
    }),
  },
};
</script>

<style lang="scss" scoped>
.list {
  &__button {
    background: none;
    border: none;
    font-size: 16px;
    position: relative;
    color: var(--primary-color);
  }

  &__button + &__button {
    padding-left: 10px;
    margin-left: 5px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 12px;
      width: 1px;
      background: $white;
    }
  }

  &__triad {
    padding: 6px 0;
    border-bottom: 1px dashed $light-grey;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    animation: all 300ms ease-in-out;

    @include up-to-md {
      flex-direction: column;
    }

    &.-disableBgColor {
      background-color: $light-red;
    }

    &.-heading {
      font-weight: bold;
      color: var(--primary-color);

      @include up-to-md {
        display: none;
      }
    }

    &:hover {
      background: $lighter-grey;
    }

    &:last-child {
      border: none;
    }
  }

  &__column {
    width: 22%;
    padding-right: 10px;

    @include up-to-md {
      width: 100%;
      margin-bottom: 10px;
    }

    &.-sortable {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      cursor: pointer;
    }

    &.-action {
      width: 15%;

      @include up-to-md {
        width: 100%;
      }
    }
  }

  &__action {
    text-align: right;
    min-width: 50px;
  }

  .badge {
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    min-width: 23px;
    max-width: 23px;
    height: 23px;
    font-size: 10px;
    background: $orange;
    display: none;

    @include up-to-md {
      display: flex;
    }

    &.-apprentice {
      background: $green;
    }

    &.-tutor {
      background: $purple;
    }

    &.-apprenticeMaster {
      background: $red;
    }
  }

  .accordion {
    svg {
      transform: rotate(-180deg);
      transition: all 300ms ease-in-out;
    }

    &.collapsed {
      svg {
        transform: rotate(0deg);
      }
    }
  }

  .list__secondary_button {
    border: none;
    background-color: inherit;
    color: var(--primary-color);
    font-size: 16px;
  }
}

.-offset {
  @include up-to-md {
    margin-left: 23px;
  }
  margin-left: 0;
}

.-manager {
  @include up-to-md {
    display: none;
  }
}

.accordion {
  margin-bottom: 0 !important;
}

.name {
  margin-top: 3px;
}

.userTriad:hover {
  text-decoration: underline;
}

.deleteAll {
  &:hover {
    color: $grey;
    background-color: $red;
    border-radius: 5px;
  }
}

.button_accordion {
  border: none;
  background-color: transparent;
  color: var(--primary-color);
}
</style>
