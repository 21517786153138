<template>
  <div
    :class="
      'd-none d-md-block gridRow' + (itemModel.selected ? ' selected' : '')
    "
  >
    <div class="row">
      <div class="col-md-3 mb-3 mb-md-0">
        <span class="d-inline d-md-none fw-bold textStyle">{{
          $t("studea_manager.questionnaire.columns")
        }}</span>
      </div>
      <div class="col-md-9">
        <VueDraggableNext
          :animation="200"
          :delay="200"
          :delay-on-touch-only="true"
          :list="itemModel.valueList"
          class="gridRow__values"
          handle=".gridRow__grip"
        >
          <div
            v-for="(column, index) in itemModel.valueList"
            :key="column"
            :class="'gridRow__value' + (itemModel.selected ? ' selected' : '')"
          >
            <div v-if="itemModel.selected" class="gridRow__grip">
              <FontAwesomeIcon icon="fa-light fa-grip-lines" />
            </div>
            <div>
              <input
                v-if="itemModel.selected && !front"
                v-model="column.value"
                class="form-control"
                placeholder="Valeur"
                type="text"
                @input="update"
              />

              <span v-if="!front && column.error" class="error">
                <FontAwesomeIcon icon="fa-light fa-triangle-exclamation" />
                {{ column.error }}
              </span>
            </div>

            <input
              v-if="
                itemModel.selected &&
                !front &&
                isChoice() &&
                itemModel.displayMark
              "
              v-model="column.mark"
              class="form-control ms-2 gridRow__mark"
              placeholder="Note"
              type="text"
              @input="update"
            />
            <button
              v-if="
                itemModel.selected &&
                !front &&
                Object.keys(itemModel.valueList).length > 1
              "
              class="btn btn-cluster"
              v-tooltip="{
                title: $t('studea_manager.questionnaire.remove_column'),
              }"
              type="button"
              @click="remove(index)"
            >
              <FontAwesomeIcon icon="fa-light fa-trash" />
            </button>
            <div
              v-if="!itemModel.selected || front"
              class="textStyle text-center w-100"
            >
              {{
                column.value
                  ? column.value
                  : $t("studea_manager.questionnaire.untitled_column")
              }}
            </div>
          </div>
          <div v-if="itemModel.comments" class="gridRow__value comments">
            <input
              v-if="itemModel.selected"
              v-model="itemModel.commentsLabel"
              class="form-control"
              placeholder="Intitulé commentaire"
              type="text"
              @input="update"
            />
            <div v-else class="textStyle text-center w-100">
              {{ itemModel.commentsLabel }}
            </div>
          </div>
        </VueDraggableNext>
      </div>
    </div>
  </div>
</template>

<script>
import { notificationManager } from "@/services/utilities/notification-manager";
import { VueDraggableNext } from "vue-draggable-next";
import { questionnaireManager } from "@/services/questionnaire/questionnaire-manager";
import { formValidator } from "@/services/form/form-validator";

export default {
  name: "QuestionnaireRowHeadComponent",
  components: { VueDraggableNext },
  props: {
    model: {
      type: Object,
      required: true,
    },
    front: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      itemModel: this.model,
    };
  },

  methods: {
    update() {
      if (this.front) {
        formValidator.validModel(this.questionnaireModel[this.rowItem.id]);
      } else {
        questionnaireManager.validQuestion(this.model);
      }
      this.$emit("update");
    },

    remove(index) {
      notificationManager
        .showAlert(
          "error",
          this.$t("studea_manager.questionnaire.remove_column_warning"),
          "",
          true,
          true,
        )
        .then((res) => {
          if (res.isConfirmed) {
            this.itemModel.valueList.splice(index, 1);
            this.update();
          }
        });
    },

    isChoice() {
      return [2, 3, 4].includes(this.itemModel.type);
    },
  },
};
</script>

<style lang="scss" scoped>
.gridRow {
  padding: 10px 20px 10px 0;
  position: relative;
  border: none;

  @include up-to-md {
    display: none;
  }

  &.selected {
    .gridRow__values {
      @include up-to-md {
        flex-direction: column;
      }
    }

    .gridRow__values {
      @include up-to-md {
        padding-left: 20px;
      }
    }
  }

  @include up-to-md {
    padding: 20px 10px;
  }

  &__values {
    display: flex;
    align-items: center;

    @include up-to-md {
      display: none;
    }
  }

  &__value {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &.comments {
      padding-right: 25px;
    }

    @include up-to-md {
      width: 100%;
    }
  }

  &__value + &__value {
    margin-left: 10px;

    @include up-to-md {
      margin-left: 0;
    }
  }

  &__mark {
    max-width: 100px;
  }

  &__grip {
    position: absolute;
    top: -22px;
    left: 50%;
    cursor: grab;

    @include up-to-md {
      top: 50%;
      transform: translateY(-50%);
      left: -20px;
    }
  }
}

.btn {
  &-cluster {
    background: none;
    color: $red !important;
    border: none;
    padding: 5px;
  }
}

.error {
  color: $red;
  font-size: 14px;
  font-weight: 700;
  margin-top: 5px;
}
</style>
