<template>
  <div
    v-if="
      !this.front ||
      rowItem.selected ||
      this.questionnaireModel[this.rowItem.id]
    "
    :class="
      'gridRow' +
      (parent.selected ? ' selected' : '') +
      (index === 0 ? ' -first' : '')
    "
  >
    <div v-if="!front && parent.selected" class="gridRow__grip">
      <FontAwesomeIcon icon="fa-light fa-grip-lines-vertical" />
    </div>
    <div class="row">
      <div class="col-md-3 my-2 my-md-0">
        <div
          v-if="
            rowItem.selected ||
            (parent.openedQuestions &&
              this.questionnaireModel[this.rowItem.id] &&
              typeof rowItem.id !== 'number')
          "
          class="d-flex align-items-md-center gridRow__settings"
        >
          <div
            v-if="!front"
            class="d-flex align-items-md-center align-items-start gridRow__setting mt-2 mt-xl-0"
          >
            <button
              class="btn btn-cluster bgStyle"
              v-tooltip="{
                title: $t('studea_manager.questionnaire.row_settings'),
              }"
              type="button"
              @click="settings"
            >
              <FontAwesomeIcon icon="fa-light fa-users" />
            </button>
            <button
              class="btn btn-cluster bgStyle"
              v-if="isFieldSkillAvailable()"
              v-tooltip="{
                title: $t('studea_manager.questionnaire.skill_settings'),
              }"
              type="button"
              @click="skills"
            >
              <FontAwesomeIcon icon="fa-light fa-medal" />
            </button>
            <button
              class="btn btn-cluster bgStyle"
              v-tooltip="{
                title: $t('studea_manager.questionnaire.duplicate_row'),
              }"
              type="button"
              @click="duplicate"
            >
              <FontAwesomeIcon icon="fa-light fa-copy" />
            </button>
            <button
              class="btn btn-cluster btn-danger me-1"
              v-tooltip="{
                title: $t('studea_manager.questionnaire.remove_row'),
              }"
              type="button"
              @click="remove"
            >
              <FontAwesomeIcon icon="fa-light fa-trash" />
            </button>
          </div>
          <InputTypeComponent
            v-if="parent.openedQuestionsType === 1"
            :model="rowModel"
            @update="updateRowTitle"
            @draft="saveDraft"
            @focused="focused"
          />
          <TextareaTypeComponent
            v-else
            :model="rowModel"
            @update="updateRowTitle"
            @draft="saveDraft"
            @focused="focused"
          />
        </div>

        <div v-else class="gridRow__label textStyle">
          <div
            class="gridRow__title -text__mobile__bold"
            v-if="
              !this.front ||
              (this.front && this.questionnaireModel[this.rowItem.id])
            "
            v-html="
              rowItem.title
                ? rowItem.title
                : $t('studea_manager.questionnaire.empty_line')
            "
          ></div>
          <span
            v-if="rowItem.tipActive && rowItem.tip"
            class="form-info mb-0 ms-2"
            v-tooltip="{ title: rowItem.tip }"
          >
            <FontAwesomeIcon icon="fa-light fa-circle-question" />
          </span>
          <QuestionnaireSignBadgeComponent
            v-if="!front || (front && typeof rowItem.id === 'number')"
            :itemModel="rowItem"
            position="centered"
          />
        </div>
        <div
          v-if="!front && rowItem.tipActive && rowItem.selected"
          class="mt-2 gridRow__tip"
        >
          <InputTypeComponent :model="tipField" @update="updateTip" />
        </div>
        <div v-if="!front && rowItem.selected" class="gridRow__tools">
          <div class="required">
            {{ $t("studea_manager.questionnaire.tip") }}
            <SwitchTypeComponent :model="tip" @update="setTip" />
          </div>
          <div class="required">
            {{ $t("studea_manager.questionnaire.required") }}
            <SwitchTypeComponent :model="required" @update="setRequired" />
          </div>
        </div>
        <div
          v-if="!front && rowItem.titleError && rowItem.selected"
          class="error"
        >
          <FontAwesomeIcon icon="fa-light fa-triangle-exclamation" />
          {{ $t("studea_manager.questionnaire.title_missing") }}
        </div>
        <div
          v-if="!front && rowItem.accessError && rowItem.selected"
          class="error"
        >
          <FontAwesomeIcon icon="fa-light fa-triangle-exclamation" />
          {{ $t("studea_manager.questionnaire.access_missing") }}
        </div>
      </div>
      <div class="col-md-9 mt-md-0 mt-3">
        <div
          :class="
            'd-flex flex-column flex-md-row gridRow__values' +
            (this.parent.selected ? ' -selected' : '')
          "
        >
          <template v-for="(value, index) in values" :key="value">
            <div
              v-if="
                !this.front || (this.front && questionnaireModel[rowItem.id])
              "
              :data-index="index"
              :data-row="rowItem.id"
              class="gridRow__value"
            >
              <label class="d-inline d-md-none mb-1 gridRow__mobile__label">{{
                value.value
              }}</label>
              <ModelComponent
                :model="rowFieldModel(index)"
                v-if="
                  !questionnaire.userHasSigned &&
                  row.access.includes(this.questionnaire.userProfile)
                "
                @draft="saveDraft"
                @focused="focused"
                @update="updateRowField"
              />
              <FontAwesomeIcon
                icon="fa-light fa-check"
                class="check-icon"
                v-else-if="rowFieldModel(index).vars.value"
              />
            </div>
          </template>
          <div
            v-if="
              parent.comments &&
              ((!this.disabled() && questionnaireModel[rowItem.id]) ||
                !this.front)
            "
            :data-comment="rowItem.id"
            class="gridRow__value comments"
          >
            <ModelComponent
              v-if="row.access.includes(this.questionnaire.userProfile)"
              :model="rowCommentModel(index)"
              @draft="saveDraft"
              @focused="focused"
              @update="updateRowField"
            />
          </div>
          <div
            v-if="
              questionnaireModel[rowItem.id] &&
              parent.openedQuestions &&
              !this.disabled()
            "
            class="ms-2"
          >
            <button
              v-if="typeof rowItem.id === 'string'"
              v-tooltip="{
                title: this.$t('studea_manager.questionnaire.remove_row'),
              }"
              class="btn btn-icon -red gridRow__trash"
              @click="removeRow(rowItem.id)"
            >
              <FontAwesomeIcon icon="fa-light fa-trash" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModelComponent from "@/components/form/ModelComponent.vue";
import SwitchTypeComponent from "@/components/form/SwitchTypeComponent.vue";
import InputTypeComponent from "@/components/form/InputTypeComponent.vue";
import { mapActions, mapState } from "pinia";
import { formValidator } from "@/services/form/form-validator";
import TextareaTypeComponent from "@/components/form/TextareaTypeComponent.vue";
import QuestionnaireSignBadgeComponent from "@/components/questionnaire/editor/QuestionnaireSignBadgeComponent.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { notificationManager } from "@/services/utilities/notification-manager";
import tinymce from "tinymce";
import { questionnaireManager } from "@/services/questionnaire/questionnaire-manager";
import { useUserStore } from "@/store/user/user";
import { useQuestionnaireStore } from "@/store/questionnaire/questionnaire";
import { useTriadStore } from "@/store/triad/triad";

export default {
  name: "QuestionnaireRowComponent",
  components: {
    FontAwesomeIcon,
    QuestionnaireSignBadgeComponent,
    TextareaTypeComponent,
    InputTypeComponent,
    SwitchTypeComponent,
    ModelComponent,
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
    values: {
      type: Array,
      required: true,
    },
    parent: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
      default: 0,
    },
    front: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      rowItem: this.row,
    };
  },
  computed: {
    isChoice() {
      return [2, 3, 4].includes(this.parent.type);
    },

    isCheckbox() {
      return [4].includes(this.parent.type);
    },

    tip() {
      return {
        vars: {
          value: this.rowItem.tipActive,
          id: "tip-" + this.index,
          name: "tip-" + this.rowItem.title,
          block_prefixes: ["", "choice"],
        },
      };
    },

    required() {
      return {
        vars: {
          value: this.rowItem.required === 1,
          id: "required-" + this.index,
          name: "required-" + this.rowItem.title,
          block_prefixes: ["", "choice"],
        },
      };
    },

    tipField() {
      return {
        vars: {
          value: this.rowItem.tip,
          id: "tip-" + this.index,
          name: "tip-" + this.rowItem.title,
          block_prefixes: ["", "choice"],
          attr: {
            placeholder: "Contenu de l'infobulle",
          },
        },
      };
    },

    rowModel() {
      return {
        vars: {
          block_prefixes: ["", "text"],
          required: false,
          label: false,
          value: this.rowItem.title ? this.rowItem.title : "",
          attr: {
            placeholder: this.rowItem.title
              ? this.rowItem.title
              : "Titre de la ligne",
          },
          name: "row-" + this.rowItem.id,
          id: "row-" + this.rowItem.id,
          disabled: this.disabled(true),
        },
      };
    },

    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
    ...mapState(useQuestionnaireStore, {
      questionnaire: (store) => store.questionnaire,
      questionnaireModel: (store) => store.model,
    }),
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
  },
  methods: {
    placeholder() {
      let placeholder = "";
      switch (this.parent.type) {
        case 0:
          placeholder = this.$t("studea_manager.questionnaire.short_answer");
          break;
        case 1:
          placeholder = this.$t("studea_manager.questionnaire.long_answer");
          break;
        case 2:
          placeholder = this.$t("studea_manager.questionnaire.choose_option");
          break;
        case 8:
          placeholder = "25";
          break;
        case 11:
          placeholder = "2024-01-01";
          break;
        case 12:
          placeholder = "2024-01-01 11:00";
          break;
      }
      return placeholder;
    },

    rowCommentModel(index) {
      let value = "";
      if (this.front) {
        value =
          this.questionnaireModel[this.rowItem.id] &&
          this.questionnaireModel[this.rowItem.id].vars.comments
            ? this.questionnaireModel[this.rowItem.id].vars.comments
            : "";
      }

      return {
        vars: {
          value: value,
          id: this.rowItem.title + "-comment-" + index,
          name: this.rowItem.title + "-comment-" + index,
          label: false,
          block_prefixes:
            this.parent.commentsType === 1
              ? ["", "text"]
              : ["", "text", "textarea"],
          required: false,
          disabled: this.disabled(),
          attr: {
            placeholder: "Commentaire...",
          },
        },
      };
    },

    focused(id) {
      this.$emit("focused", id);
    },

    disabled(rowTitle = false) {
      let available =
        this.questionnaire.userHasToComplete &&
        !this.questionnaire.userHasSigned &&
        this.rowItem.enabled;

      if (!this.front && rowTitle) {
        available = true;
      }

      if (
        this.front &&
        this.user.roles &&
        this.user.roles.includes("ROLE_STUDEA_ADMIN")
      ) {
        available = false;
      }

      return !available;
    },

    removeRow(id) {
      notificationManager
        .showAlert(
          "error",
          this.$t("remove_answer_title"),
          this.$t("remove_answer"),
          true,
          true,
        )
        .then((res) => {
          if (res.isConfirmed) {
            if (!id.includes("new-")) {
              this.questionnaireModel[id]["delete"] = true;
              this.$emit("draft", this.questionnaireModel[id]);
            }
            delete this.questionnaireModel[id];
          }
        });
    },

    rowFieldModel(index) {
      let field = {
        vars: {
          value: this.placeholder(),
          id: index ? index + "-" + this.rowItem.id : this.rowItem.id,
          name: "name-" + this.rowItem.id,
          label: false,
          block_prefixes: this.block_prefixes(),
          required: this.front
            ? this.rowItem.required && !this.disabled()
            : false,
          disabled: this.disabled(),
          help: this.rowItem.tip,
          attr: {
            placeholder: this.placeholder(),
            class: "questionnaire-editor-cluster",
          },
          row: true,
        },
      };

      if (this.isChoice) {
        switch (this.parent.type) {
          case 2:
            field["vars"]["multiple"] = false;
            field["vars"]["expanded"] = false;
            break;
          case 3:
            field["vars"]["multiple"] = false;
            field["vars"]["expanded"] = true;
            break;
          case 4:
            field["vars"]["multiple"] = true;
            field["vars"]["expanded"] = true;
            break;
        }

        field["vars"]["choices"] = [
          {
            value: this.front
              ? this.parent.valueList[index].value
              : "value-" + index,
            data: this.front
              ? this.parent.valueList[index].value
              : "value-" + index,
            label: "",
          },
        ];
      }
      if (this.front) {
        field.vars.id = index ? index + "-" + this.rowItem.id : this.rowItem.id;
        field.vars.value =
          this.questionnaireModel[this.rowItem.id] &&
          this.questionnaireModel[this.rowItem.id].vars.value
            ? this.questionnaireModel[this.rowItem.id].vars.value[index]
            : "";
        if (this.isCheckbox) {
          if (field.vars.choices[0].value === field.vars.value) {
            field.vars.value = [
              {
                value: field.vars.value,
                data: field.vars.value,
                checked: true,
              },
            ];

            field.vars.choices = field.vars.value;
          }
        }

        field.vars.error =
          this.questionnaireModel[this.rowItem.id] &&
          this.questionnaireModel[this.rowItem.id].vars.error
            ? this.questionnaireModel[this.rowItem.id].vars.error[index]
            : null;
      }
      return field;
    },

    block_prefixes() {
      let block_prefixes = [];
      switch (this.parent.type) {
        case 0:
          block_prefixes = ["", "text"];
          break;
        case 1:
          block_prefixes = ["", "text", "textarea", "zama_tiny_mce_type"];
          break;
        case 2:
        case 3:
        case 4:
          block_prefixes = ["", "choice"];
          break;
        case 8:
          block_prefixes = ["", "number"];
          break;
        case 10:
          block_prefixes = ["", "file"];
          break;
        case 11:
          block_prefixes = ["", "date"];
          break;
        case 12:
          block_prefixes = ["", "date", "datetime"];
          break;
      }
      return block_prefixes;
    },

    updateRowTitle(value) {
      this.rowItem.title = value;
      this.rowModel.vars.value = value;

      if (this.front && this.questionnaireModel[this.rowItem.id]) {
        this.questionnaireModel[this.rowItem.id].name = value;
      }

      this.update();
    },

    setTip(value) {
      this.rowItem.tipActive = value;
      if (!value) {
        this.rowItem.tip = "";
      }
      this.update();
    },

    update() {
      if (this.front) {
        formValidator.validModel(this.questionnaireModel[this.rowItem.id]);
      } else {
        questionnaireManager.validQuestion(this.parent);
      }
      this.$emit("update");
    },

    updateRowField() {
      const columns = document.querySelectorAll(
        '[data-row="' + this.rowItem.id + '"]',
      );
      let answers = [];
      columns.forEach((col) => {
        let value = "";
        const input = col.querySelector("input, textarea");
        if (this.parent.type === 1) {
          value = tinymce.get(input.id).getContent();
        } else {
          value = input.value;
        }
        const index = col.getAttribute("data-index");

        if (this.isChoice) {
          if (this.parent.type) {
            if (input.checked) {
              answers[index] = value;
            } else {
              answers[index] = "";
            }
          }
        } else {
          answers[index] = value;
        }
      });

      const comment = document.querySelector(
        '[data-comment="' + this.rowItem.id + '"]',
      );

      let commentValue = null;
      if (comment) {
        commentValue = comment.querySelector(
          this.parent.commentsType === 1 ? "input" : "textarea",
        ).value;
      }

      this.setAnswer({
        id: this.rowItem.id,
        value: answers,
        comments: commentValue,
      });
      this.update();
    },

    saveDraft(model) {
      if (this.rowItem.required && !this.rowItem.disabled) {
        formValidator.validModel(model);
      }
      this.$emit("draft", model);
    },

    setRequired(value) {
      this.rowItem.required = value;
      this.update();
    },

    remove() {
      this.$emit("remove");
    },

    updateTip(value) {
      this.rowItem.tip = value;
      this.$emit("update");
    },

    settings() {
      this.$emit("settings", { item: this.row });
    },

    skills() {
      this.$emit("skills", { item: this.row });
    },

    duplicate() {
      this.$emit("duplicate", this.index);
    },

    isFieldSkillAvailable() {
      return (
        this.isChoice &&
        this.parent.displayMark &&
        this.parent.valueList[0].mark
      );
    },

    ...mapActions(useQuestionnaireStore, { setAnswer: "setAnswer" }),
  },
};
</script>

<style lang="scss" scoped>
.gridRow {
  padding: 15px 20px 15px 0;
  border-top: 1px solid $grey;
  position: relative;

  @include up-to-md {
    padding: 10px 20px;
  }

  &.-first {
    border-top: none;
  }

  &.selected {
    .gridRow__values {
      @include up-to-md {
        display: none;
      }
    }
  }

  &__trash {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &__settings {
    @include up-to-xl {
      flex-direction: column-reverse;
    }
  }

  &__setting {
    @include up-to-xl {
      width: 100%;
    }
  }

  &.-head {
    border: none;
  }

  &__grip {
    position: absolute;
    right: -35px;
    top: 0;
    height: 100%;
    width: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: move;

    @include up-to-md {
      right: -5px;
    }
  }

  &__tools {
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &__rows {
    background: $white;
    border-radius: 6px;
    padding: 15px;
  }

  &__values {
    display: flex;
    align-items: flex-start;
    height: 100%;

    &.-selected {
      align-items: center;
    }
  }

  &__title {
    &.-text__mobile__bold {
      @include up-to-md {
        font-weight: 700;
      }
    }
  }

  &__value {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    @include up-to-md {
      align-items: start;
    }

    &.comments {
      padding-right: 15px;
    }
  }

  &__value + &__value {
    margin-left: 10px;

    @include up-to-md {
      margin-left: 0;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    height: 100%;
    white-space: pre-wrap;
    position: relative;
    z-index: 2;
    padding-right: 65px;
  }

  &__mobile__label {
    display: none;
    margin-bottom: 7px;

    @include up-to-md {
      display: block;
    }
  }
}

.required {
  display: flex;
  align-items: center;
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid $grey;

  &:first-child {
    border-left: none;
    margin-left: 0;
    padding-left: 0;

    @include up-to-md {
      margin-right: 15px;
    }
  }

  @include up-to-xl {
    border: none;
    margin-left: 0;
    padding-left: 0;
    margin-top: 10px;
  }
}

.btn {
  &-cluster {
    background: none;
    color: var(--primary-color) !important;
    border: none;
    padding: 5px;
  }

  &-danger {
    color: $red !important;
  }
}

.check-icon {
  font-size: 22px;
  color: $green;
}

.error {
  color: $red;
  font-size: 14px;
  font-weight: 700;
  margin-top: 5px;
}
</style>
